<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Lịch sử sửa chữa phương tiện</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button @click="btnSearch()" class="md-raised md-primary">
                            Tìm kiếm
                            <md-tooltip>Click để tìm kiếm</md-tooltip>
                        </md-button>
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="exportExcel()" @click="exportExcel()" class="md-raised md-primary btn-add">Export<md-tooltip>Xuất file Excel</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="vehicleName" @md-selected="getVehicleSelected" :md-options="vehicles" @md-changed="getVehicles"  @md-opened="getVehicles">
                                    <label>Tất cả phương tiện</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.licensePlate }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openVehicle()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm phương tiện</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo biển số xe</label>
                                <md-input v-model="search.licensePlate"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="search.fromDate" :md-model-type="String" md-immediately :class="{'md-invalid': submitted && search.fromDate == null }">
                                <label>Từ ngày</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="search.toDate" :md-model-type="String" md-immediately :class="{'md-invalid': submitted && search.toDate == null }">
                                <label>Tới ngày</label>
                            </md-datepicker>
                        </div>
                    </div>
                </div>
                <div class="table-content">
                    <div class="table-fixed" style="height: calc(100vh - 260px); overflow-x: auto; width: 100%;">
                        <table class="data-table" style="width: 2000px !important;">
                            <thead>
                                <tr>
                                    <th rowspan="2" style="background-color: #FFE3E3;">#</th>
                                    <th rowspan="2" style="width:120px; background-color: #FFE3E3;">Tạm ứng</th> 
                                    <th rowspan="2" style="width:120px; background-color: #FFE3E3;">Quyết toán</th> 
                                    <th rowspan="2" style="width:120px; background-color: #FFE3E3;">Ngày sửa chữa</th>
                                    <th rowspan="2" style="width:120px; background-color: #FFE3E3;">Mã dự toán</th> 
                                    <th rowspan="2" style="width:120px; background-color: #FFE3E3;">Người yêu cầu</th> 
                                    <th rowspan="2" style="width:120px; background-color: #FFE3E3;">Phương tiện</th> 
                                    <th rowspan="2" style="width:150px; background-color: #FFE3E3;">Vật tư</th> 
                                    <th rowspan="2" style="width:110px; background-color: #FFE3E3;">Nguồn</th>
                                    <th rowspan="2" style="width:120px; background-color: #FFE3E3;">ĐVT</th> 
                                    <th rowspan="2" style="width:100px; background-color: #FFE3E3;">Số lượng</th>
                                    <th colspan="3" style="width:450px; background-color: #B4E380;">Dự toán</th>
                                    <th colspan="3" style="width:150px; background-color: #FFD18E;">Tạm ứng</th>
                                    <th colspan="3" style="width:450px; background-color: #7ED4AD;">Quyết toán</th>
                                </tr>
                                <tr> 
                                    <th class="right" style="width:150px; background-color: #B4E380;">Số tiền</th>
                                    <th class="right" style="width:150px; background-color: #B4E380;">VAT</th>
                                    <th class="right" style="width:150px; background-color: #B4E380;">Thành tiền</th>
                                    <th class="right" style="width:150px; background-color: #FFD18E;">Số tiền</th>
                                    <th class="right" style="width:150px; background-color: #FFD18E;">VAT</th>
                                    <th class="right" style="width:150px; background-color: #FFD18E;">Thành tiền</th>
                                    <th class="right" style="width:150px; background-color: #7ED4AD;">Số tiền</th>
                                    <th class="right" style="width:150px; background-color: #7ED4AD;">VAT</th>
                                    <th class="right" style="width:150px; background-color: #7ED4AD;">Thành tiền</th>
                                </tr> 
                            </thead>    
                            <tbody v-if="loadding == true" style="height: 150px;">
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalItems > 0">
                                <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                    <td class="center">{{index + 1}}</td>
                                    <td class="center">
                                        <md-chip  v-if="item.isAdvancePayment" class="md-primary">Đã tạm ứng</md-chip>
                                        <md-chip v-else>Chưa tạm ứng</md-chip>
                                    </td>
                                    <td class="center">
                                        <md-chip  v-if="item.isInvoice" class="md-primary">Đã quyết toán</md-chip>
                                        <md-chip v-else>Chưa quyết toán</md-chip>
                                    </td>
                                    <td class="center">
                                        <div>{{ item.estimateDate }}</div>
                                    </td>
                                    <td class="left">
                                        <router-link :to="'repair-estimate/view/' + item.estimateId" target="_blank">
                                            {{item.estimateCode}}
                                        </router-link>
                                    </td>
                                    <td class="left">
                                        <div>{{ item.requestStaff }}</div>
                                    </td>
                                    <td class="center">
                                        <div>{{ item.licensePlate }}</div>
                                    </td>
                                    <td class="left">
                                        <div>{{ item.title }}</div>
                                    </td>
                                    <td class="center">
                                        <div>{{ item.source }}</div>
                                    </td> 
                                    <td class="center">
                                        <div>{{ item.unit }}</div>
                                    </td> 
                                    <td class="center">
                                        {{formatNumber(item.quantity)}}
                                    </td> 
                                    
                                    <td class="number-right">
                                        <div>{{formatNumber(item.price)}}</div>
                                    </td> 
                                    <td class="number-right">
                                        {{formatNumber(item.vat) + '%'}}
                                    </td>
                                    <td class="number-right">
                                        {{formatNumber(item.amount)}}
                                    </td>
                                    <td class="number-right">
                                        {{formatNumber(item.advancePaymentPrice)}}
                                    </td>
                                    <td class="number-right">
                                        {{formatNumber(item.advancePaymentVat)}}
                                    </td>
                                    <td class="number-right">
                                        {{formatNumber(item.advancePaymentAmount)}}
                                    </td>
                                    <td class="number-right">
                                        {{formatNumber(item.invoicePrice)}}
                                    </td>
                                    <td class="number-right">
                                        {{formatNumber(item.invoiceVat)}}
                                    </td>
                                    <td class="number-right">
                                        {{formatNumber(item.invoiceAmount)}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">Trang đầu</a>
                                    <a @click="onPage(search.pageIndex - 1)">Trang trước</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>Page</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">of <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">Rows/page:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">Xem:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> bản ghi</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">Trang sau</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">Trang cuối</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vehicleList ref="vehicleList" @close="closeVehicle"/>
    </div>
</template>

<script>
    import reportRepairService from '../../../api/reportRepairService';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';
    import vehicleService from '../../../api/vehicleService';
    import vehicleList from '../../../components/popup/_VehicleList.vue';
    import { APP_CONFIG } from '@/utils/constants';
    
    export default {
        components: {
            vehicleList
        },
        metaInfo: {
            title: 'Lịch sử sửa chữa phương tiện'
        },
        data() {
            return {
                loadding: false,
                search: { pageIndex: 1, pageSize: common.pageSize, vehicleId: 0, licensePlate: '', fromDate: this.getRangeDate(-7), toDate: common.dateNow },
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                id: 0,
                vehicleName: '',
                vehicles: [],
                submitted: false,
            }
        },
        created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            if(this.$route.query.licensePlate != undefined && this.$route.query.licensePlate !== ''){
               this.search.licensePlate = this.$route.query.licensePlate;
               this.search.fromDate = "01-01-2020";
            }
            this.filter();
        },
        mounted() {
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            btnSearch(){
                this.submitted = true;
                if(this.search.fromDate == null){
                    messageBox.showWarning("Vui lòng chọn từ ngày");
                    return;
                }
                else if(this.search.toDate == null){
                    messageBox.showWarning("Vui lòng chọn tới ngày");
                    return;
                }   
                this.filter();
            },

            exportExcel(){
                const link = document.createElement('a')
                link.href = APP_CONFIG.apiUrl + "/report-repair/export-report-repair-history?vehicleId=" + 
                this.search.vehicleId + "&licensePlate=" + this.search.licensePlate 
                + "&fromDate=" + this.search.fromDate + "&toDate=" + this.search.toDate;

                link.target = '_blank';
                link.click()
                URL.revokeObjectURL(link.href)
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search = { pageIndex: 1, pageSize: common.pageSize, customerId: 0, orderName: '', noBooking: '', serial: '', fromDate: this.getRangeDate(-7), toDate: common.dateNow, orderId: 0 };
                this.getData();
            },

            filter(){
                this.getData();
            },

            getData(){
                this.loadding = true;
                reportRepairService.getReportHistories(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            //Vehicle
            closeVehicle(item){
                this.vehicleName = item.licensePlate;
                this.search.vehicleId = item.id;
                this.$refs.vehicleList.close();
            },

            getVehicleSelected(val){
                this.search.vehicleId = val.id;
                this.vehicleName = val.licensePlate;
            },

            openVehicle(){
                this.$refs.vehicleList.open();
            },

            getVehicles(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, licensePlate: searchTerm };
                vehicleService.getVehicles(search).then((response) => {
                    if(response.statusCode == 200){
                        this.vehicles = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
        },
        watch: {
            vehicleName: function (val) { 
                if(val == ''){
                    this.search.vehicleId = 0;
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
        }
    }

</script>

<style scoped>
    .table-fixed table thead tr:nth-child(2) th {
        position: -webkit-sticky;
        position:sticky;
        top: 35px;
        height: 35px;
    }
</style>
